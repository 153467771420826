import { useState, useEffect } from "react";
//import { Navigation } from "./components/navigation";
//import { Header } from "./components/header";
//import { Features } from "./components/features";
//import { About } from "./components/about";
//import { Services } from "./components/services";
//import { Gallery } from "./components/gallery";
//import { Testimonials } from "./components/testimonials";
//import { Team } from "./components/Team";
//import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
//import "./App.css";
import Preloader from "./Preloader";
import Timer from "./Timer";
import "./styles.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">
      <div className="container">
        <h1>
          X Nova Trade
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Preloader />
      </div>
    </div>
  );
};

export default App;

/*
<Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />

      <Contact data={landingPageData.Contact} />

<Features data={landingPageData.Features} />
<Team data={landingPageData.Team} />
<Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />

*/
